@font-face {
  font-family: 'neueMachina';
  src: url(public/fonts/NeueMachina-Regular2.woff2) format('woff2');
  font-weight: normal;
  font-display: block;
}

@font-face {
  font-family: 'trasandina';
  src: url(public/fonts/Trasandina-Book.woff2) format('woff2');
  font-weight: 400;
  font-display: block;
}

@font-face {
  font-family: 'trasandina';
  src: url(public/fonts/Trasandina-Thin.woff2) format('woff2');
  font-weight: 300;
  font-display: block;
}

@font-face {
  font-family: 'trasandina';
  src: url(public/fonts/Trasandina-Medium.woff2) format('woff2');
  font-weight: 500;
  font-display: block;
}
